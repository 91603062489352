import { regForm } from '../../contexts/regForm';
import './Button.css';

function ButtonEnrol() {
  return (
    <>
      <div className="d-flex row justify-content-center text-center mt-5 ">
        <div>
          <a href={regForm} target={'_blank'} rel="noreferrer">
            <button
              type="submit"
              className=" button-fs-16s fw-bold fs-5 bg-green-100 enrol-btn white-100"
              size="lg"
            >
              Enroll
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default ButtonEnrol;
