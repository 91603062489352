import React from 'react'
import BenefitsDesktop from './BenefitsDesktop'
import BenefitsMobile from './BenefitsMobile'

function LandBenefit() {
  return (
    <>
        <BenefitsMobile/>
        <BenefitsDesktop/>
    
    </>
  )
}

export default LandBenefit