import React from "react";
import { Container } from "react-bootstrap";
import "./teams.css";

// images
import TeamMember1 from "../../Images/Team Members/aniebet.svg";
import TeamMember2 from "../../Images/Team Members/lawrence.svg";
import TeamMember3 from "../../Images/Team Members/simple.svg";
import TeamMember4 from "../../Images/Team Members/ogochuks.svg";
import TeamMember5 from "../../Images/Team Members/ike.svg";
import TeamMember6 from "../../Images/Team Members/david.svg";
import TeamMember7 from "../../Images/Team Members/akin.svg";
import TeamMember8 from "../../Images/Team Members/fae.svg";
import TeamMember9 from "../../Images/Team Members/chris.svg";
import TeamMember10 from "../../Images/Team Members/victor.svg";
import TeamMember11 from "../../Images/Team Members/boma.svg";
import TeamMember12 from "../../Images/Team Members/menin.svg";

const TeamMember = [
  {
    name: "Engr. Aniebiet Udoh",
    title: "CEO",
    img: TeamMember1,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Lawrence Akubue",
    title: "Manager",
    img: TeamMember2,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Ikechukwu Achom",
    title: "Program Manager",
    img: TeamMember5,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Sherif-Deen A.R",
    title: "Frontend Developer",
    img: TeamMember3,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Ogochukwu Odom",
    title: "React Developer",
    img: TeamMember4,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "David Edu",
    title: "Product Designer",
    img: TeamMember6,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Akinbobola Akinuliola",
    title: "Frontend Developer",
    img: TeamMember7,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Favour Sunday",
    title: "Product Designer",
    img: TeamMember8,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Christopher Sesugh",
    title: "Backend Developer",
    img: TeamMember9,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Victor Ukok",
    title: "Backend Developer",
    img: TeamMember10,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Boma Agina-Obu",
    title: "Product Designer",
    img: TeamMember11,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
  {
    name: "Menim Joseph",
    title: "Product Designer",
    img: TeamMember12,
    about:
      "A very good front end dev with a lot of potential to fight and win multiple wins and gigs and at hj",
  },
];

const Teams = () => {
  return (
    <>
      <Container className='px-md-0 mb-5 mt-2'>
        <div class='py-3 container-sm'>
          <div class='content-first pb-5'>
            <h1 class='text-center sec-text-col'>TEAM MEMBERS</h1>
          </div>
          <div className=''>
            <div class='row'>
              {TeamMember.map((team) => {
                return (
                  <div class='col-lg-3 col-md-6 d-flex align-items-stretch mb-5'>
                    <div class='card member-shadow border-0 w-100'>
                      <div class='img-box img-parent'>
                        <img
                          src={team.img}
                          class='card-img-top h-100 h-md-auto object-fit-cover'
                          alt='...'
                        />
                        <div class='img-icons px-2 text-center'>
                          {/* <p class='font-bold'>{team.about}</p> */}
                        </div>
                      </div>
                      <div class='card-body text-center'>
                        <h5 class='card-title sec-text-col'>{team.name}</h5>
                        <p class='card-text text-secondary'>{team.title}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Teams;
