import benefit1 from "../../Images/landing-card-images/benefits-1.svg";
import benefit2 from "../../Images/landing-card-images/benefits-2.svg";
import benefit3 from "../../Images/landing-card-images/benefits-3.svg";
const benefitsdata = [
  {
    id: 1,
    title: "LEARN FROM EXPERTS",
    description:
      "At KodeCamp, you receive relevant and valuable training and mentorship to accelerate your tech journey",
    image: benefit1,
  },
  {
    id: 2,
    title: "REAL-WORLD EXPERIENCE",
    description:
      "We partner with diverse tech companies, providing interns with opportunities to apply their skills in real-life corporate setting",
    image: benefit2,
  },
  {
    id: 3,
    title: "RECOGNIZED CERTIFICATION",
    description:
      "In addition to our focus on knowledge acquisition, we also award globally recognised and accepted certifications",
    image: benefit3,
  },
];
export function getBenefitCardData() {
  return benefitsdata;
}
