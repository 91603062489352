import { useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import dayjs from "dayjs";
import { useEffect } from "react";

const KodeCamp30 = () => {
  let countDownTimeMs = Date.parse("Aug 30 2022 00:00:00");
  const [remainingTime, setRemainingTime] = useState({
    seconds: "00",
    minutes: "00",
    hours: "00",
    days: "00",
  });

  if (
    remainingTime.seconds < 0 ||
    remainingTime.minutes < 0 ||
    remainingTime.hours < 0 ||
    remainingTime.days < 0
  ) {
    setRemainingTime({
      seconds: "0",
      minutes: "0",
      hours: "0",
      days: "0",
    });
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countDownTimeMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countDownTimeMs]);

  function updateRemainingTime(countdown) {
    const timeStampDayJs = dayjs(countdown);
    const now = dayjs();
    const seconds = timeStampDayJs.diff(now, "seconds") % 60;
    const minutes = timeStampDayJs.diff(now, "minutes") % 60;
    const hours = timeStampDayJs.diff(now, "hours") % 24;
    const days = timeStampDayJs.diff(now, "days");
    setRemainingTime({
      seconds: seconds,
      minutes: minutes,
      hours: hours,
      days: days,
    });
  }

  const KodeCamp3_0_Style = {
    fontSize: "1.5em",
    fontWeight: "600",
    lineHeight: "1.8125em",
    width: "11.25em",
    height: "1.8125em",
  };

  return (
    <Container>
      <Container>
        <Row>
          <Col>
            <h2
              className='deep-blue-100 text-center mx-auto'
              id='kodecamp3_0_heading'
              style={KodeCamp3_0_Style}
            >
              KodeCamp 4.0
            </h2>
          </Col>
        </Row>
        
      </Container>

      {/* <Container id='data_container' style={Data_Container_Style}>
        <Row>
          <Col>
            <h3
              className='deep-blue-100 text-center mx-auto mt-sm-5'
              id='data_heading'
              style={Data_Style}
            >
              Your Data is Safe with us
            </h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <p
              className='sonic-silver text-center mx-auto'
              id='text-below-data'
              style={textBelowData}
            >
              Whatever data given to us is treated with confidentiality and will
              not be disclosed to any third party, neither wil we spam your
              mails or send any unrelated data. We are keen to ensure there is
              no breach of information on our part and you can be rest assured
              that we will protect any information gotten
            </p>
          </Col>
        </Row>

        <Row>
          <Col className='text-center mt-4 mb-4  mt-md-5 mb-md-5'>
            <Button
              className='bg-green-100 white-100'
              id='btn-join-works'
              style={btnJoinWorks}
            >
              Sign up now
            </Button>
          </Col>
        </Row>
      </Container> */}
    </Container>
  );
};

export default KodeCamp30;
