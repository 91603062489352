import ContactUsForm from './ContactUsForm';
import PageWrapper from '../../layout/PageWapper/PageWrapper';
import './Contact.css';

function ContactUs() {
  return (
    <>
      <PageWrapper>
        <div className=''>
          <ContactUsForm />
        </div>
      </PageWrapper>
    </>
  );
}

export default ContactUs;
